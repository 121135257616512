@import "commonStyle";

table {
    width: 100%;
    margin-bottom: $verticalSpacing;
    
    
    thead {
        border: 0;
      
        th {
            background-color: var(--colour-contrast-high);
            border: 1px solid var(--colour-contrast-high);
            font-weight: normal;
            color: var(--colour-contrast-low);
            
            &:first-of-type {
                text-align: left; 
            }
        }
    }
    
    th, td {
        padding: .5em;
        vertical-align: middle;
    }
    
    tbody {
        
        tr {
            margin-bottom: 1em;
            border: 2px solid var(--colour-contrast-high);
            
            
            &:last-of-type {
                margin-bottom: 0; 
            }
            
            &:nth-of-type(even) {
                background-color: var(--colour-contrast-low);
            }
        }
      
        th[scope="row"] {
            background-color: var(--colour-contrast-high);
            color: var(--colour-contrast-low);
        }
    }
}