@import 'commonStyle';
/////////////////////////////////
// Archive specific styling    //
/////////////////////////////////

.archives {
    @extend .post;
}

// .archive-link-wrapper {
//     padding: 0.25rem 0;
//     box-shadow: 2px 2px 15px #ccc;
// }

.archive-links, .archives .grouping-list {

    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    margin: 1rem 0;

    li.tag, li.category {
        margin: 0.5rem;
    }
}

.archives .grouping-list {

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20ch, 1fr));
    grid-gap: 1rem;
}

li.tag {
    margin: 0 0.5rem, 0, 0;
}

.archive-links {
    .active {
        border-color: var(--colour-secondary);
        border-width: 0 0 1px 0;
        border-style: solid;
    }

    li {
        margin: 0 0.5rem;

        @include respond-to(handhelds) {
            margin-left: 0;
            margin-right: 1rem;
        }
    }



    a, a:hover {
        text-decoration: none;
    }
}

.archives {
    // @include sitePadding();

    .fa-li, .fa-li.fa-lg {
        margin-left: 0;
    }

    .groupedPosts {
 
        // @include clear();
    
        margin: 1em 0 0 0;
        list-style: none;
    
        .meta {
            width: 6ch;
            border-color: var(--colour-secondary);
            border-right: 1px;
            vertical-align: middle;
        }

        .meta-year {
            width: 12ch;
        }
        
        .title {
            overflow: hidden;
            padding-left: 4px;
        }   

        ul {

            margin: 1em 0;
            // @include for-md-up {
            //     margin-left: 1.5em;
            // }
        }
    }

    ul {
        
        list-style: none;
        
        h3.year, h3.tag, h3.category {

        	font-weight: $contentFontWeight;
            vertical-align: top;
            text-align: center;

            // @include for-md-up {
            //     text-align: left;
            // }
        }
        
        .post-list-item {
            display: flex;
            margin: 0.5em 0;
        }

        .title {
            flex: 1;
        }

        .meta {

        	border-color: var(--colour-secondary);
        	border-right: 1px;
            vertical-align: middle;
        }
        
        .title {
            overflow: hidden;
            padding-left: 4px;
        }
    }

}