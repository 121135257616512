/* This is basically the output of
 * rougify style base16.solarized.dark > _sass/_syntax_dark.scss
 * but with a few changes:
 *
 * - wrapped with the data-theme grouping
 * - refrences to #002b36 replaced with "var(--colour-bg)"
 */

[data-theme="dark"] {
  .highlight table td { padding: 5px; }
  .highlight table pre { margin: 0; }
  .highlight, .highlight .w {
    color: #93a1a1;
    background-color: var(--colour-bg);
  }
  .highlight .err {
    color: var(--colour-bg);
    background-color: #dc322f;
  }
  .highlight .c, .highlight .ch, .highlight .cd, .highlight .cm, .highlight .cpf, .highlight .c1, .highlight .cs {
    color: #657b83;
  }
  .highlight .cp {
    color: #b58900;
  }
  .highlight .nt {
    color: #b58900;
  }
  .highlight .o, .highlight .ow {
    color: #93a1a1;
  }
  .highlight .p, .highlight .pi {
    color: #93a1a1;
  }
  .highlight .gi {
    color: #859900;
  }
  .highlight .gd {
    color: #dc322f;
  }
  .highlight .gh {
    color: #268bd2;
    background-color: var(--colour-bg);
    font-weight: bold;
  }
  .highlight .k, .highlight .kn, .highlight .kp, .highlight .kr, .highlight .kv {
    color: #6c71c4;
  }
  .highlight .kc {
    color: #cb4b16;
  }
  .highlight .kt {
    color: #cb4b16;
  }
  .highlight .kd {
    color: #cb4b16;
  }
  .highlight .s, .highlight .sa, .highlight .sb, .highlight .sc, .highlight .dl, .highlight .sd, .highlight .s2, .highlight .sh, .highlight .sx, .highlight .s1 {
    color: #859900;
  }
  .highlight .sr {
    color: #2aa198;
  }
  .highlight .si {
    color: #d33682;
  }
  .highlight .se {
    color: #d33682;
  }
  .highlight .nn {
    color: #b58900;
  }
  .highlight .nc {
    color: #b58900;
  }
  .highlight .no {
    color: #b58900;
  }
  .highlight .na {
    color: #268bd2;
  }
  .highlight .m, .highlight .mb, .highlight .mf, .highlight .mh, .highlight .mi, .highlight .il, .highlight .mo, .highlight .mx {
    color: #859900;
  }
  .highlight .ss {
    color: #859900;
  }
}