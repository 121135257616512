// Common style information between print and screen




//
// Variables 
//

$lineHeight: 1.5;
$lineHeightEM: #{$lineHeight}em;




$fontSizeTitle: 6em;
$fontSizeStandard: 18px;
$fontSizeSmaller: 16px;
$fontSizeSmallest: 14px;

$fontSizeTitlePrint: 32pt;
$fontSizeStandardPrint: 12pt;

$verticalSpacing: 1em;
$horizontalSpacing: 2em;

//TODO: Remove
$thinSpacing: 2px;
///////////////////
// Common Mixins //
///////////////////

@mixin respond-to($media) {
    
    @if $media == handhelds {
        @media #{$mqHandheld} {
            @content
        }
    } @else if $media == tablets {
        @media #{$mqiPad},#{$mqTablet} { 
            @content 
        }
    } @else if $media == print {
        @media only print { 
            @content 
        }
    } @else {
        // Standard screen
        @content
    }
        
}