/*
 * Icon set from https://icomoon.io/app/
 * 
 * Usage is like: <span class="icon-chevrons-down"></span>
 */
  @font-face {
    font-family: 'icomoon';
    src:  url('/assets/fonts/icomoon/fonts/icomoon.eot?himhik');
    src:  url('/assets/fonts/icomoon/fonts/icomoon.eot?himhik#iefix') format('embedded-opentype'),
      url('/assets/fonts/icomoon/fonts/icomoon.ttf?himhik') format('truetype'),
      url('/assets/fonts/icomoon/fonts/icomoon.woff?himhik') format('woff'),
      url('/assets/fonts/icomoon/fonts/icomoon.svg?himhik#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-mastodon:before {
    content: "\e902";
    /* color: #3088d4; */
  }
  .icon-chevrons-down:before {
    content: "\e900";
  }
  .icon-chevrons-up:before {
    content: "\e901";
  }
  .icon-file-text:before {
    content: "\e903";
  }
  .icon-link:before {
    content: "\e905";
  }
  .icon-send:before {
    content: "\e907";
  }
  .icon-price-tags:before {
    content: "\e936";
  }
  .icon-envelop:before {
    content: "\e945";
  }
  .icon-calendar:before {
    content: "\e953";
  }
  .icon-link1:before {
    content: "\e9cb";
  }
  .icon-bookmarks:before {
    content: "\e9d3";
  }
  .icon-embed2:before {
    content: "\ea80";
  }
  .icon-twitter:before {
    content: "\ea96";
  }
  .icon-rss:before {
    content: "\ea9b";
  }
  .icon-github1:before {
    content: "\eab0";
  }
  .icon-stackoverflow:before {
    content: "\ead0";
  }
  